// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["__sentryRewritesTunnelPath__"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"A2yOiZg_G5outeOOoSdhi"};globalThis["__sentryBasePath"] = undefined;globalThis["__rewriteFramesAssetPrefixPath__"] = "";import {browserTracingIntegration, init} from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT

init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    tracesSampleRate: 0.1,
    integrations: [
        browserTracingIntegration({
            tracePropagationTargets: ['localhost', 'stockholm', /^\//],
        }),
    ],
})
